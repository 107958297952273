export type EnvName = 'development' | 'staging' | 'production';

export type TenantAlias =
  | 'advantageelectricsupply'
  | 'appliedautomation'
  | 'automationguru'
  | 'controlsforautomation'
  | 'demo'
  | 'mechatronicsolutions'
  | 'mqautomation'
  | 'northwestmotion'
  | 'powermatic'
  | 'quotebeam'
  | 'test'
  // Prospects December
  | 'avranindustrial'
  | 'iriselectronics'
  | 'mancocontrols'
  | 'priinc'
  | 'sensorsite'
  | 'superiorelectricmotors'
  // Prospects January Week 1
  | 'bdindustrial'
  | 'controlplus'
  | 'proautomation'
  | 'proindustrialcontrol'
  | 'saddlebrookcontrols'
  // Prospects January Week 2
  | 'aaelectricmotors'
  | 'bodinedecatur'
  | 'adconengineering'
  | 'ac-dc'
  | 'bestwayelectric'
  // Prospects January Week 3
  | 'foremostindustrialtechnologies'
  | 'dptechnologies'
  | 'crossautomation'
  | 'controlsource'
  | 'controldesignsupply'
  // Prospects January Week 4
  | 'abacontrols'
  // Prospects February Week 1
  | 'jashomer'
  | 'summitindustries'
  | 'futuraautomation'
  | 'afp'
  | 'aaaelectricmotors'
  | 'branominstruments';

export const ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS: TenantAlias =
  'advantageelectricsupply';
export const APPLIEDAUTOMATION_TENANT_ALIAS: TenantAlias = 'appliedautomation';
export const AUTOMATIONGURU_TENANT_ALIAS = 'automationguru';
export const CONTROLSFORAUTOMATION_TENANT_ALIAS: TenantAlias =
  'controlsforautomation';
export const DEMO_TENANT_ALIAS: TenantAlias = 'demo';
export const MQAUTOMATION_TENANT_ALIAS: TenantAlias = 'mqautomation';
export const NORTHWESTMOTION_TENANT_ALIAS: TenantAlias = 'northwestmotion';
export const POWERMATIC_TENANT_ALIAS: TenantAlias = 'powermatic';
export const QUOTEBEAM_TENANT_ALIAS: TenantAlias = 'quotebeam';

// Prospects December
export const AVRANINDUSTRIAL_TENANT_ALIAS: TenantAlias = 'avranindustrial';
export const IRISELECTRONICS_TENANT_ALIAS = 'iriselectronics';
export const MANCOCONTROLS_TENANT_ALIAS: TenantAlias = 'mancocontrols';
export const MECHATRONICSOLUTIONS_TENANT_ALIAS: TenantAlias =
  'mechatronicsolutions';
export const PRIINC_TENANT_ALIAS: TenantAlias = 'priinc';
export const SENSORSITE_TENANT_ALIAS: TenantAlias = 'sensorsite';
export const SUPERIORELECTRICMOTORS_TENANT_ALIAS: TenantAlias =
  'superiorelectricmotors';

// Prospects January Week 1
export const BDINDUSTRIAL_TENANT_ALIAS: TenantAlias = 'bdindustrial';
export const CONTROLPLUS_TENANT_ALIAS: TenantAlias = 'controlplus';
export const PROAUTOMATION_TENANT_ALIAS: TenantAlias = 'proautomation';
export const PROINDUSTRIALCONTROL_TENANT_ALIAS: TenantAlias =
  'proindustrialcontrol';
export const SADDLEBROOKCONTROLS_TENANT_ALIAS: TenantAlias =
  'saddlebrookcontrols';

// Prospects January Week 2
export const AAELECTRICMOTORS_TENANT_ALIAS: TenantAlias = 'aaelectricmotors';
export const BODINEDECATUR_TENANT_ALIAS: TenantAlias = 'bodinedecatur';
export const ADCONENGINEERING_TENANT_ALIAS: TenantAlias = 'adconengineering';
export const ACDC_TENANT_ALIAS: TenantAlias = 'ac-dc';
export const BESTWAYELECTRIC_TENANT_ALIAS: TenantAlias = 'bestwayelectric';

// Prospects January Week 3
export const FOREMOSTINDUSTRIALTECHNOLOGIES_TENANT_ALIAS: TenantAlias =
  'foremostindustrialtechnologies';
export const DPTECHNOLOGIES_TENANT_ALIAS: TenantAlias = 'dptechnologies';
export const CROSSAUTOMATION_TENANT_ALIAS: TenantAlias = 'crossautomation';
export const CONTROLSOURCE_TENANT_ALIAS: TenantAlias = 'controlsource';
export const CONTROLDESIGNSUPPLY_TENANT_ALIAS: TenantAlias =
  'controldesignsupply';

// Prospects January Week 4
export const ABACONTROLS_TENANT_ALIAS: TenantAlias = 'abacontrols';

// Prospects February Week 1
export const JASHOMER_TENANT_ALIAS: TenantAlias = 'jashomer';
export const SUMMITINDUSTRIES_TENANT_ALIAS: TenantAlias = 'summitindustries';
export const FUTURAAUTOMATION_TENANT_ALIAS: TenantAlias = 'futuraautomation';
export const AFP_TENANT_ALIAS: TenantAlias = 'afp';
export const AAAELECTRICMOTORS_TENANT_ALIAS: TenantAlias = 'aaaelectricmotors';
export const BRANOMINSTRUMENTS_TENANT_ALIAS: TenantAlias = 'branominstruments';

export type TenantAliasConfig = {
  tenantAlias: TenantAlias;
  companyName: string;
  appID: number;
  tenantID: number;
  isDemo: boolean;
  sitesHosts: string[];
  agentHosts: string[];
  apiHost?: string;
};

type TenantAliasConfigList = Record<
  TenantAlias,
  Record<EnvName, TenantAliasConfig>
>;

const getTenantAliasConfigOverride = () => {
  try {
    return JSON.parse(
      process.env.TENANT_ALIAS_MAP_OVERRIDE || '{}',
    ) as TenantAliasConfigList;
  } catch (e) {
    console.warn('Invalid process.env.TENANT_ALIAS_MAP_OVERRIDE detected', e);
    return {};
  }
};

export const tenantAliasConfig: TenantAliasConfigList = {
  advantageelectricsupply: {
    development: {
      tenantAlias: ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS,
      companyName: 'Advantage Electric Supply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      // 'localhost' is needed for when you run dev server widget locally in SPS.
      // In that case, the login request referer header is localhost and in order
      // to API to match the tenant, we need to add localhost to the sitesHosts.
      sitesHosts: ['advantageelectricsupply.localhost.com', 'localhost'],
      agentHosts: ['agentadvantageelectricsupply.localhost.com'],
    },
    staging: {
      tenantAlias: ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS,
      companyName: 'Advantage Electric Supply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: [
        'advantageelectricsupply.salespilotsites-staging.com',
        'advantageelectricsupplyproxy.salespilotsites-staging.com',
      ],
      agentHosts: ['agent.advantageelectricsupply.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS,
      companyName: 'Advantage Electric Supply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: [
        'www.advantageelectricsupply.com',
        'advantageelectricsupply.com',
        'advantageelectricsupply.salespilotsites.com',
      ],
      agentHosts: [
        'agent.advantageelectricsupply.com',
        'agent.advantageelectricsupply.salespilotsites.com',
      ],
      apiHost: 'api.advantageelectricsupply.com',
    },
  },
  appliedautomation: {
    development: {
      tenantAlias: APPLIEDAUTOMATION_TENANT_ALIAS,
      companyName: 'Applied Automation',
      appID: 80,
      tenantID: 4925,
      isDemo: false,
      sitesHosts: ['appliedautomation.localhost.com', 'localhost'],
      agentHosts: ['agentappliedautomation.localhost.com'],
    },
    staging: {
      tenantAlias: APPLIEDAUTOMATION_TENANT_ALIAS,
      companyName: 'Applied Automation',
      appID: 47,
      tenantID: 2769,
      isDemo: false,
      sitesHosts: ['appliedautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.appliedautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: APPLIEDAUTOMATION_TENANT_ALIAS,
      companyName: 'Applied Automation',
      appID: 80,
      tenantID: 4925,
      isDemo: false,
      sitesHosts: [
        'www.appliedautomation.com',
        'appliedautomation.com',
        'appliedautomation.salespilotsites.com',
        'airtacsales.com',
        'airtac-pneumatics.com',
      ],
      agentHosts: [
        'agent.appliedautomation.com',
        'agent.appliedautomation.salespilotsites.com',
      ],
    },
  },
  automationguru: {
    development: {
      tenantAlias: AUTOMATIONGURU_TENANT_ALIAS,
      companyName: 'Automation Guru',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['automationguru.localhost.com', 'localhost'],
      agentHosts: ['agentautomationguru.localhost.com'],
    },
    staging: {
      tenantAlias: AUTOMATIONGURU_TENANT_ALIAS,
      companyName: 'Automation Guru',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['automationguru.salespilotsites-staging.com'],
      agentHosts: ['agent.automationguru.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: AUTOMATIONGURU_TENANT_ALIAS,
      companyName: 'Automation Guru',
      appID: 89,
      tenantID: 5654,
      isDemo: false,
      sitesHosts: [
        'www.automationguru.shop',
        'automationguru.shop',
        'automationguru.salespilotsites.com',
      ],
      agentHosts: [
        'agent.automationguru.shop',
        'agent.automationguru.salespilotsites.com',
      ],
    },
  },
  controlsforautomation: {
    development: {
      tenantAlias: CONTROLSFORAUTOMATION_TENANT_ALIAS,
      companyName: 'Controls For Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controlsforautomation.localhost.com', 'localhost'],
      agentHosts: ['agentcontrolsforautomation.localhost.com'],
    },
    staging: {
      tenantAlias: CONTROLSFORAUTOMATION_TENANT_ALIAS,
      companyName: 'Controls For Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controlsforautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.controlsforautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: CONTROLSFORAUTOMATION_TENANT_ALIAS,
      companyName: 'Controls For Automation',
      appID: 81,
      tenantID: 5244,
      isDemo: false,
      sitesHosts: [
        'controlsforautomation.salespilotsites.com',
        'controlsforautomation.com',
      ],
      agentHosts: [
        'agent.controlsforautomation.com',
        'agent.controlsforautomation.salespilotsites.com',
      ],
    },
  },
  demo: {
    development: {
      tenantAlias: DEMO_TENANT_ALIAS,
      companyName: 'Sales Pilot',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['demo.localhost.com', 'localhost'],
      agentHosts: ['agentdemo.localhost.com'],
    },
    staging: {
      tenantAlias: DEMO_TENANT_ALIAS,
      companyName: 'Sales Pilot',
      appID: 46,
      tenantID: 2723,
      isDemo: false,
      sitesHosts: ['demo.salespilotsites-staging.com'],
      agentHosts: ['agent.demo.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: DEMO_TENANT_ALIAS,
      companyName: 'Sales Pilot',
      appID: 46,
      tenantID: 3825,
      isDemo: false,
      sitesHosts: ['demo.salespilotsites.com'],
      agentHosts: ['agent.demo.salespilotsites.com'],
    },
  },
  mechatronicsolutions: {
    development: {
      tenantAlias: MECHATRONICSOLUTIONS_TENANT_ALIAS,
      companyName: 'Mechatronic Solutions',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['mechatronicsolutions.localhost.com', 'localhost'],
      agentHosts: ['agentmechatronicsolutions.localhost.com'],
    },
    staging: {
      tenantAlias: MECHATRONICSOLUTIONS_TENANT_ALIAS,
      companyName: 'Mechatronic Solutions',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['mechatronicsolutions.salespilotsites-staging.com'],
      agentHosts: ['agent.mechatronicsolutions.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: MECHATRONICSOLUTIONS_TENANT_ALIAS,
      companyName: 'Mechatronic Solutions',
      appID: 47,
      tenantID: 4251,
      isDemo: false,
      sitesHosts: [
        'mechatronicsolutions.com',
        'www.mechatronicsolutions.com',
        'mechatronicsolutions.salespilotsites.com',
      ],
      agentHosts: [
        'agent.mechatronicsolutions.com',
        'agent.mechatronicsolutions.salespilotsites.com',
      ],
    },
  },
  mqautomation: {
    development: {
      tenantAlias: MQAUTOMATION_TENANT_ALIAS,
      companyName: 'MQ Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['mqautomation.localhost.com', 'localhost'],
      agentHosts: ['agentmqautomation.localhost.com'],
    },
    staging: {
      tenantAlias: MQAUTOMATION_TENANT_ALIAS,
      companyName: 'MQ Automation',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['mqautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.mqautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: MQAUTOMATION_TENANT_ALIAS,
      companyName: 'MQ Automation',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: [
        'www.mqautomation.com',
        'mqautomation.com',
        'mqautomation.salespilotsites.com',
      ],
      agentHosts: [
        'agent.mqautomation.com',
        'agent.mqautomation.salespilotsites.com',
      ],
    },
  },
  northwestmotion: {
    development: {
      tenantAlias: NORTHWESTMOTION_TENANT_ALIAS,
      companyName: 'Northwest Motion',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['northwestmotion.localhost.com', 'localhost'],
      agentHosts: ['agentnorthwestmotion.localhost.com'],
    },
    staging: {
      tenantAlias: NORTHWESTMOTION_TENANT_ALIAS,
      companyName: 'Northwest Motion',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['northwestmotion.salespilotsites-staging.com'],
      agentHosts: ['agent.northwestmotion.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: NORTHWESTMOTION_TENANT_ALIAS,
      companyName: 'Northwest Motion',
      appID: 83,
      tenantID: 3520,
      isDemo: false,
      sitesHosts: ['www.nwmotion.com', 'northwestmotion.salespilotsites.com'],
      agentHosts: ['agent.northwestmotion.salespilotsites.com'],
    },
  },
  powermatic: {
    development: {
      tenantAlias: POWERMATIC_TENANT_ALIAS,
      companyName: 'Powermatic Associates',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['powermatic.localhost.com', 'localhost'],
      agentHosts: ['agentpowermatic.localhost.com'],
    },
    staging: {
      tenantAlias: POWERMATIC_TENANT_ALIAS,
      companyName: 'Powermatic Associates',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['powermatic.salespilotsites-staging.com'],
      agentHosts: ['agent.powermatic.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: POWERMATIC_TENANT_ALIAS,
      companyName: 'Powermatic Associates',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: [
        'www.powermatic.net',
        'powermatic.net',
        'store.powermatic.net',
        'powermatic.salespilotsites.com',
      ],
      agentHosts: [
        'agent.powermatic.salespilotsites.com',
        'agent.powermatic.net',
      ],
    },
  },
  quotebeam: {
    development: {
      tenantAlias: QUOTEBEAM_TENANT_ALIAS,
      companyName: 'Quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    staging: {
      tenantAlias: QUOTEBEAM_TENANT_ALIAS,
      companyName: 'Quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    production: {
      tenantAlias: QUOTEBEAM_TENANT_ALIAS,
      companyName: 'Quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: ['quotebeam.com'],
      agentHosts: [],
    },
  },
  test: {
    development: {
      tenantAlias: 'test',
      companyName: 'TEST INTERNAL',
      appID: 47,
      tenantID: 2727,
      isDemo: false,
      sitesHosts: ['test.localhost.com'],
      agentHosts: ['agenttest.localhost.com'],
    },
    staging: {
      tenantAlias: 'test',
      companyName: 'TEST INTERNAL',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    production: {
      tenantAlias: 'test',
      companyName: 'TEST INTERNAL',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
  },
  // Prospects December
  avranindustrial: {
    development: {
      tenantAlias: AVRANINDUSTRIAL_TENANT_ALIAS,
      companyName: 'Avran Industrial',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['avranindustrial.localhost.com', 'localhost'],
      agentHosts: ['agentavranindustrial.localhost.com'],
    },
    staging: {
      tenantAlias: AVRANINDUSTRIAL_TENANT_ALIAS,
      companyName: 'Avran Industrial',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['avranindustrial.salespilotsites-staging.com'],
      agentHosts: ['agent.avranindustrial.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: AVRANINDUSTRIAL_TENANT_ALIAS,
      companyName: 'Avran Industrial',
      appID: 84,
      tenantID: 5578,
      isDemo: false,
      sitesHosts: ['avranindustrial.salespilotsites.com'],
      agentHosts: ['agent.avranindustrial.salespilotsites.com'],
    },
  },
  iriselectronics: {
    development: {
      tenantAlias: IRISELECTRONICS_TENANT_ALIAS,
      companyName: 'Iris Electronics',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['iriselectronics.localhost.com', 'localhost'],
      agentHosts: ['agentiriselectronics.localhost.com'],
    },
    staging: {
      tenantAlias: IRISELECTRONICS_TENANT_ALIAS,
      companyName: 'Iris Electronics',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['iriselectronics.salespilotsites-staging.com'],
      agentHosts: ['agent.iriselectronics.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: IRISELECTRONICS_TENANT_ALIAS,
      companyName: 'Iris Electronics',
      appID: 82,
      tenantID: 192,
      isDemo: false,
      sitesHosts: ['iriselectronics.salespilotsites.com'],
      agentHosts: ['agent.iriselectronics.salespilotsites.com'],
    },
  },
  mancocontrols: {
    development: {
      tenantAlias: MANCOCONTROLS_TENANT_ALIAS,
      companyName: 'Manco Controls',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['mancocontrols.localhost.com', 'localhost'],
      agentHosts: ['agentmancocontrols.localhost.com'],
    },
    staging: {
      tenantAlias: MANCOCONTROLS_TENANT_ALIAS,
      companyName: 'Manco Controls',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['mancocontrols.salespilotsites-staging.com'],
      agentHosts: ['agent.mancocontrols.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: MANCOCONTROLS_TENANT_ALIAS,
      companyName: 'Manco Controls',
      appID: 86,
      tenantID: 189,
      isDemo: false,
      sitesHosts: ['mancocontrols.salespilotsites.com'],
      agentHosts: ['agent.mancocontrols.salespilotsites.com'],
    },
  },
  priinc: {
    development: {
      tenantAlias: PRIINC_TENANT_ALIAS,
      companyName: 'Power Resource International',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['priinc.localhost.com', 'localhost'],
      agentHosts: ['agentpriinc.localhost.com'],
    },
    staging: {
      tenantAlias: PRIINC_TENANT_ALIAS,
      companyName: 'Power Resource International',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['priinc.salespilotsites-staging.com'],
      agentHosts: ['agent.priinc.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: PRIINC_TENANT_ALIAS,
      companyName: 'Power Resource International',
      appID: 88,
      tenantID: 5581,
      isDemo: false,
      sitesHosts: ['priinc.salespilotsites.com'],
      agentHosts: ['agent.priinc.salespilotsites.com'],
    },
  },
  sensorsite: {
    development: {
      tenantAlias: SENSORSITE_TENANT_ALIAS,
      companyName: 'Automation Sensors Inc',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['sensorsite.localhost.com', 'localhost'],
      agentHosts: ['agentsensorsite.localhost.com'],
    },
    staging: {
      tenantAlias: SENSORSITE_TENANT_ALIAS,
      companyName: 'Automation Sensors Inc',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['sensorsite.salespilotsites-staging.com'],
      agentHosts: ['agent.sensorsite.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: SENSORSITE_TENANT_ALIAS,
      companyName: 'Automation Sensors Inc',
      appID: 87,
      tenantID: 5580,
      isDemo: false,
      sitesHosts: ['sensorsite.salespilotsites.com'],
      agentHosts: ['agent.sensorsite.salespilotsites.com'],
    },
  },
  superiorelectricmotors: {
    development: {
      tenantAlias: SUPERIORELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'Superior Electric Motors',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['superiorelectricmotors.localhost.com', 'localhost'],
      agentHosts: ['agentsuperiorelectricmotors.localhost.com'],
    },
    staging: {
      tenantAlias: SUPERIORELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'Superior Electric Motors',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['superiorelectricmotors.salespilotsites-staging.com'],
      agentHosts: ['agent.superiorelectricmotors.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: SUPERIORELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'Superior Electric Motors',
      appID: 85,
      tenantID: 5579,
      isDemo: false,
      sitesHosts: ['superiorelectricmotors.salespilotsites.com'],
      agentHosts: ['agent.superiorelectricmotors.salespilotsites.com'],
    },
  },
  // Prospects January Week 1
  bdindustrial: {
    development: {
      tenantAlias: BDINDUSTRIAL_TENANT_ALIAS,
      companyName: 'B&D Industrial',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['bdindustrial.localhost.com', 'localhost'],
      agentHosts: ['agentbdindustrial.localhost.com'],
    },
    staging: {
      tenantAlias: BDINDUSTRIAL_TENANT_ALIAS,
      companyName: 'B&D Industrial',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['bdindustrial.salespilotsites-staging.com'],
      agentHosts: ['agent.bdindustrial.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: BDINDUSTRIAL_TENANT_ALIAS,
      companyName: 'B&D Industrial',
      appID: 122,
      tenantID: 5800,
      isDemo: false,
      sitesHosts: ['bdindustrial.salespilotsites.com'],
      agentHosts: ['agent.bdindustrial.salespilotsites.com'],
    },
  },
  controlplus: {
    development: {
      tenantAlias: CONTROLPLUS_TENANT_ALIAS,
      companyName: 'Control Plus',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controlplus.localhost.com', 'localhost'],
      agentHosts: ['agentcontrolplus.localhost.com'],
    },
    staging: {
      tenantAlias: CONTROLPLUS_TENANT_ALIAS,
      companyName: 'Control Plus',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controlplus.salespilotsites-staging.com'],
      agentHosts: ['agent.controlplus.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: CONTROLPLUS_TENANT_ALIAS,
      companyName: 'Control Plus',
      appID: 125,
      tenantID: 5811,
      isDemo: false,
      sitesHosts: ['controlplus.salespilotsites.com'],
      agentHosts: ['agent.controlplus.salespilotsites.com'],
    },
  },
  proautomation: {
    development: {
      tenantAlias: PROAUTOMATION_TENANT_ALIAS,
      companyName: 'Pro Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['proautomation.localhost.com', 'localhost'],
      agentHosts: ['agentproautomation.localhost.com'],
    },
    staging: {
      tenantAlias: PROAUTOMATION_TENANT_ALIAS,
      companyName: 'Pro Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['proautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.proautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: PROAUTOMATION_TENANT_ALIAS,
      companyName: 'Pro Automation',
      appID: 123,
      tenantID: 5808,
      isDemo: false,
      sitesHosts: ['proautomation.salespilotsites.com'],
      agentHosts: ['agent.proautomation.salespilotsites.com'],
    },
  },
  proindustrialcontrol: {
    development: {
      tenantAlias: PROINDUSTRIALCONTROL_TENANT_ALIAS,
      companyName: 'Pro Industrial Control',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['proindustrialcontrol.localhost.com', 'localhost'],
      agentHosts: ['agentproindustrialcontrol.localhost.com'],
    },
    staging: {
      tenantAlias: PROINDUSTRIALCONTROL_TENANT_ALIAS,
      companyName: 'Pro Industrial Control',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['proindustrialcontrol.salespilotsites-staging.com'],
      agentHosts: ['agent.proindustrialcontrol.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: PROINDUSTRIALCONTROL_TENANT_ALIAS,
      companyName: 'Pro Industrial Control',
      appID: 124,
      tenantID: 5810,
      isDemo: false,
      sitesHosts: ['proindustrialcontrol.salespilotsites.com'],
      agentHosts: ['agent.proindustrialcontrol.salespilotsites.com'],
    },
  },
  saddlebrookcontrols: {
    development: {
      tenantAlias: SADDLEBROOKCONTROLS_TENANT_ALIAS,
      companyName: 'Saddle Brook Controls',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['saddlebrookcontrols.localhost.com', 'localhost'],
      agentHosts: ['agentsaddlebrookcontrols.localhost.com'],
    },
    staging: {
      tenantAlias: SADDLEBROOKCONTROLS_TENANT_ALIAS,
      companyName: 'Saddle Brook Controls',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['saddlebrookcontrols.salespilotsites-staging.com'],
      agentHosts: ['agent.saddlebrookcontrols.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: SADDLEBROOKCONTROLS_TENANT_ALIAS,
      companyName: 'Saddle Brook Controls',
      appID: 126,
      tenantID: 219,
      isDemo: false,
      sitesHosts: ['saddlebrookcontrols.salespilotsites.com'],
      agentHosts: ['agent.saddlebrookcontrols.salespilotsites.com'],
    },
  },
  // Prospects January Week 2
  aaelectricmotors: {
    development: {
      tenantAlias: AAELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'A&A Electric Motors & Pumps',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['aaelectricmotors.localhost.com'],
      agentHosts: ['agentaaelectricmotors.localhost.com'],
    },
    staging: {
      tenantAlias: AAELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'A&A Electric Motors & Pumps',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['aaelectricmotors.salespilotsites-staging.com'],
      agentHosts: ['agent.aaelectricmotors.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: AAELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'A&A Electric Motors & Pumps',
      appID: 127,
      tenantID: 5883,
      isDemo: false,
      sitesHosts: ['aaelectricmotors.salespilotsites.com'],
      agentHosts: ['agent.aaelectricmotors.salespilotsites.com'],
    },
  },
  bodinedecatur: {
    development: {
      tenantAlias: BODINEDECATUR_TENANT_ALIAS,
      companyName: 'Bodine Electric',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['bodinedecatur.localhost.com'],
      agentHosts: ['agentbodinedecatur.localhost.com'],
    },
    staging: {
      tenantAlias: BODINEDECATUR_TENANT_ALIAS,
      companyName: 'Bodine Electric',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['bodinedecatur.salespilotsites-staging.com'],
      agentHosts: ['agent.bodinedecatur.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: BODINEDECATUR_TENANT_ALIAS,
      companyName: 'Bodine Electric',
      appID: 132,
      tenantID: 5884,
      isDemo: false,
      sitesHosts: ['bodinedecatur.salespilotsites.com'],
      agentHosts: ['agent.bodinedecatur.salespilotsites.com'],
    },
  },
  adconengineering: {
    development: {
      tenantAlias: ADCONENGINEERING_TENANT_ALIAS,
      companyName: 'Adcon Engineering',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['adconengineering.localhost.com'],
      agentHosts: ['agentadconengineering.localhost.com'],
    },
    staging: {
      tenantAlias: ADCONENGINEERING_TENANT_ALIAS,
      companyName: 'Adcon Engineering',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['adconengineering.salespilotsites-staging.com'],
      agentHosts: ['agent.adconengineering.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: ADCONENGINEERING_TENANT_ALIAS,
      companyName: 'Adcon Engineering',
      appID: 129,
      tenantID: 5885,
      isDemo: false,
      sitesHosts: ['adconengineering.salespilotsites.com'],
      agentHosts: ['agent.adconengineering.salespilotsites.com'],
    },
  },
  'ac-dc': {
    development: {
      tenantAlias: ACDC_TENANT_ALIAS,
      companyName: 'Automation Control Distribution Company',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['ac-dc.localhost.com'],
      agentHosts: ['agentac-dc.localhost.com'],
    },
    staging: {
      tenantAlias: ACDC_TENANT_ALIAS,
      companyName: 'Automation Control Distribution Company',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['ac-dc.salespilotsites-staging.com'],
      agentHosts: ['agent.ac-dc.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: ACDC_TENANT_ALIAS,
      companyName: 'Automation Control Distribution Company',
      appID: 130,
      tenantID: 5886,
      isDemo: false,
      sitesHosts: ['ac-dc.salespilotsites.com'],
      agentHosts: ['agent.ac-dc.salespilotsites.com'],
    },
  },
  bestwayelectric: {
    development: {
      tenantAlias: BESTWAYELECTRIC_TENANT_ALIAS,
      companyName: 'Bestway Electric Motor Service',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['bestwayelectric.localhost.com'],
      agentHosts: ['agentbestwayelectric.localhost.com'],
    },
    staging: {
      tenantAlias: BESTWAYELECTRIC_TENANT_ALIAS,
      companyName: 'Bestway Electric Motor Service',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['bestwayelectric.salespilotsites-staging.com'],
      agentHosts: ['agent.bestwayelectric.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: BESTWAYELECTRIC_TENANT_ALIAS,
      companyName: 'Bestway Electric Motor Service',
      appID: 131,
      tenantID: 5887,
      isDemo: false,
      sitesHosts: ['bestwayelectric.salespilotsites.com'],
      agentHosts: ['agent.bestwayelectric.salespilotsites.com'],
    },
  },
  // January Week 3 Prospects
  foremostindustrialtechnologies: {
    development: {
      tenantAlias: FOREMOSTINDUSTRIALTECHNOLOGIES_TENANT_ALIAS,
      companyName: 'Foremost Industrial Technologies',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['foremostindustrialtechnologies.localhost.com'],
      agentHosts: ['agentforemostindustrialtechnologies.localhost.com'],
    },
    staging: {
      tenantAlias: FOREMOSTINDUSTRIALTECHNOLOGIES_TENANT_ALIAS,
      companyName: 'Foremost Industrial Technologies',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: [
        'foremostindustrialtechnologies.salespilotsites-staging.com',
      ],
      agentHosts: [
        'agent.foremostindustrialtechnologies.salespilotsites-staging.com',
      ],
    },
    production: {
      tenantAlias: FOREMOSTINDUSTRIALTECHNOLOGIES_TENANT_ALIAS,
      companyName: 'Foremost Industrial Technologies',
      appID: 133,
      tenantID: 5942,
      isDemo: false,
      sitesHosts: ['foremostindustrialtechnologies.salespilotsites.com'],
      agentHosts: ['agent.foremostindustrialtechnologies.salespilotsites.com'],
    },
  },
  dptechnologies: {
    development: {
      tenantAlias: DPTECHNOLOGIES_TENANT_ALIAS,
      companyName: 'DP Technologies',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['dptechnologies.localhost.com'],
      agentHosts: ['agentdptechnologies.localhost.com'],
    },
    staging: {
      tenantAlias: DPTECHNOLOGIES_TENANT_ALIAS,
      companyName: 'DP Technologies',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['dptechnologies.salespilotsites-staging.com'],
      agentHosts: ['agent.dptechnologies.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: DPTECHNOLOGIES_TENANT_ALIAS,
      companyName: 'DP Technologies',
      appID: 134,
      tenantID: 5943,
      isDemo: false,
      sitesHosts: ['dptechnologies.salespilotsites.com'],
      agentHosts: ['agent.dptechnologies.salespilotsites.com'],
    },
  },
  crossautomation: {
    development: {
      tenantAlias: CROSSAUTOMATION_TENANT_ALIAS,
      companyName: 'Cross Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['crossautomation.localhost.com'],
      agentHosts: ['agentcrossautomation.localhost.com'],
    },
    staging: {
      tenantAlias: CROSSAUTOMATION_TENANT_ALIAS,
      companyName: 'Cross Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['crossautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.crossautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: CROSSAUTOMATION_TENANT_ALIAS,
      companyName: 'Cross Automation',
      appID: 135,
      tenantID: 5944,
      isDemo: false,
      sitesHosts: ['crossautomation.salespilotsites.com'],
      agentHosts: ['agent.crossautomation.salespilotsites.com'],
    },
  },
  controlsource: {
    development: {
      tenantAlias: CONTROLSOURCE_TENANT_ALIAS,
      companyName: 'Control Source',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controlsource.localhost.com'],
      agentHosts: ['agentcontrolsource.localhost.com'],
    },
    staging: {
      tenantAlias: CONTROLSOURCE_TENANT_ALIAS,
      companyName: 'Control Source',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controlsource.salespilotsites-staging.com'],
      agentHosts: ['agent.controlsource.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: CONTROLSOURCE_TENANT_ALIAS,
      companyName: 'Control Source',
      appID: 136,
      tenantID: 5945,
      isDemo: false,
      sitesHosts: ['controlsource.salespilotsites.com'],
      agentHosts: ['agent.controlsource.salespilotsites.com'],
    },
  },
  controldesignsupply: {
    development: {
      tenantAlias: CONTROLDESIGNSUPPLY_TENANT_ALIAS,
      companyName: 'Control Design Supply',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controldesignsupply.localhost.com'],
      agentHosts: ['agentcontroldesignsupply.localhost.com'],
    },
    staging: {
      tenantAlias: CONTROLDESIGNSUPPLY_TENANT_ALIAS,
      companyName: 'Control Design Supply',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['controldesignsupply.salespilotsites-staging.com'],
      agentHosts: ['agent.controldesignsupply.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: CONTROLDESIGNSUPPLY_TENANT_ALIAS,
      companyName: 'Control Design Supply',
      appID: 137,
      tenantID: 5946,
      isDemo: false,
      sitesHosts: ['controldesignsupply.salespilotsites.com'],
      agentHosts: ['agent.controldesignsupply.salespilotsites.com'],
    },
  },
  // January Week 4 Prospects
  abacontrols: {
    development: {
      tenantAlias: ABACONTROLS_TENANT_ALIAS,
      companyName: 'Absolute Automation Controls',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['abacontrols.localhost.com'],
      agentHosts: ['agentabacontrols.localhost.com'],
    },
    staging: {
      tenantAlias: ABACONTROLS_TENANT_ALIAS,
      companyName: 'Absolute Automation Controls',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['abacontrols.salespilotsites-staging.com'],
      agentHosts: ['agent.abacontrols.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: ABACONTROLS_TENANT_ALIAS,
      companyName: 'Absolute Automation Controls',
      appID: 138,
      tenantID: 6216,
      isDemo: false,
      sitesHosts: ['abacontrols.salespilotsites.com'],
      agentHosts: ['agent.abacontrols.salespilotsites.com'],
    },
  },
  // February Week 1 Prospects
  jashomer: {
    development: {
      tenantAlias: JASHOMER_TENANT_ALIAS,
      companyName: 'J.A. Shomer',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['jashomer.localhost.com'],
      agentHosts: ['agentjashomer.localhost.com'],
    },
    staging: {
      tenantAlias: JASHOMER_TENANT_ALIAS,
      companyName: 'J.A. Shomer',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['jashomer.salespilotsites-staging.com'],
      agentHosts: ['agent.jashomer.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: JASHOMER_TENANT_ALIAS,
      companyName: 'J.A. Shomer',
      appID: 139,
      tenantID: 6266,
      isDemo: false,
      sitesHosts: ['jashomer.salespilotsites.com'],
      agentHosts: ['agent.jashomer.salespilotsites.com'],
    },
  },
  summitindustries: {
    development: {
      tenantAlias: SUMMITINDUSTRIES_TENANT_ALIAS,
      companyName: 'Summit Industries',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['summitindustries.localhost.com'],
      agentHosts: ['agentsummitindustries.localhost.com'],
    },
    staging: {
      tenantAlias: SUMMITINDUSTRIES_TENANT_ALIAS,
      companyName: 'Summit Industries',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['summitindustries.salespilotsites-staging.com'],
      agentHosts: ['agent.summitindustries.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: SUMMITINDUSTRIES_TENANT_ALIAS,
      companyName: 'Summit Industries',
      appID: 140,
      tenantID: 6267,
      isDemo: false,
      sitesHosts: ['summitindustries.salespilotsites.com'],
      agentHosts: ['agent.summitindustries.salespilotsites.com'],
    },
  },
  futuraautomation: {
    development: {
      tenantAlias: FUTURAAUTOMATION_TENANT_ALIAS,
      companyName: 'Futura Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['futuraautomation.localhost.com'],
      agentHosts: ['agentfuturaautomation.localhost.com'],
    },
    staging: {
      tenantAlias: FUTURAAUTOMATION_TENANT_ALIAS,
      companyName: 'Futura Automation',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['futuraautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.futuraautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: FUTURAAUTOMATION_TENANT_ALIAS,
      companyName: 'Futura Automation',
      appID: 141,
      tenantID: 6268,
      isDemo: false,
      sitesHosts: ['futuraautomation.salespilotsites.com'],
      agentHosts: ['agent.futuraautomation.salespilotsites.com'],
    },
  },
  afp: {
    development: {
      tenantAlias: AFP_TENANT_ALIAS,
      companyName: 'AFP',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['afp.localhost.com'],
      agentHosts: ['agentafp.localhost.com'],
    },
    staging: {
      tenantAlias: AFP_TENANT_ALIAS,
      companyName: 'AFP',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['afp.salespilotsites-staging.com'],
      agentHosts: ['agent.afp.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: AFP_TENANT_ALIAS,
      companyName: 'AFP',
      appID: 142,
      tenantID: 6269,
      isDemo: false,
      sitesHosts: ['afp.salespilotsites.com'],
      agentHosts: ['agent.afp.salespilotsites.com'],
    },
  },
  aaaelectricmotors: {
    development: {
      tenantAlias: AAAELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'AAA Electric Motors',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['aaaelectricmotors.localhost.com'],
      agentHosts: ['agentaaaelectricmotors.localhost.com'],
    },
    staging: {
      tenantAlias: AAAELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'AAA Electric Motors',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['aaaelectricmotors.salespilotsites-staging.com'],
      agentHosts: ['agent.aaaelectricmotors.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: AAAELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'AAA Electric Motors',
      appID: 143,
      tenantID: 6270,
      isDemo: false,
      sitesHosts: ['aaaelectricmotors.salespilotsites.com'],
      agentHosts: ['agent.aaaelectricmotors.salespilotsites.com'],
    },
  },
  branominstruments: {
    development: {
      tenantAlias: BRANOMINSTRUMENTS_TENANT_ALIAS,
      companyName: 'Branom Instruments',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['branominstruments.localhost.com'],
      agentHosts: ['agentbranominstruments.localhost.com'],
    },
    staging: {
      tenantAlias: BRANOMINSTRUMENTS_TENANT_ALIAS,
      companyName: 'Branom Instruments',
      appID: 0,
      tenantID: 0,
      isDemo: false,
      sitesHosts: ['branominstruments.salespilotsites-staging.com'],
      agentHosts: ['agent.branominstruments.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: BRANOMINSTRUMENTS_TENANT_ALIAS,
      companyName: 'Branom Instruments',
      appID: 144,
      tenantID: 6277,
      isDemo: false,
      sitesHosts: ['branominstruments.salespilotsites.com'],
      agentHosts: ['agent.branominstruments.salespilotsites.com'],
    },
  },
  ...getTenantAliasConfigOverride(),
};
const getHostName = (host: string | null) => {
  /**
   * Remove the port in case of localhost
   * @example `domain-a`, `powermatic.net`, `domain-a.localhost`
   */
  return (host || '').replace(/:\d+/g, '');
};

export const getCurrentEnvMap = (): Record<TenantAlias, TenantAliasConfig> => {
  const envName = (process.env.ENV_NAME || 'development') as EnvName;

  return Object.entries(tenantAliasConfig).reduce(
    (previousValue, [tenantAlias, allEnvConfig]) => {
      if (allEnvConfig[envName]) {
        return {
          ...previousValue,
          [tenantAlias]: allEnvConfig[envName],
        };
      }

      return previousValue;
    },
    {} as Record<TenantAlias, TenantAliasConfig>,
  );
};

export const parseHostToTenantAlias = (
  host: string | null,
  hostsKey: 'sitesHosts' | 'agentHosts',
): TenantAlias | undefined => {
  const hostname = getHostName(host);
  const currentEnvMap = getCurrentEnvMap();

  return Object.entries(currentEnvMap)
    .filter(([_tenantAlias, config]) => config[hostsKey].includes(hostname))
    .map(([tenantAlias]) => tenantAlias as TenantAlias)
    .at(0);
};

export const parseHostToTenantID = (
  host: string | null,
  hostsKey: 'sitesHosts' | 'agentHosts',
): number | undefined => {
  const hostname = getHostName(host);
  const currentEnvMap = getCurrentEnvMap();

  return Object.entries(currentEnvMap)
    .filter(([_tenantAlias, config]) => config[hostsKey].includes(hostname))
    .map(([, config]) => config.tenantID)
    .at(0);
};

export const getConfigFromTenantAlias = (
  tenantAlias: TenantAlias,
): TenantAliasConfig => {
  const currentEnvMap = getCurrentEnvMap();
  const config = currentEnvMap[tenantAlias];

  if (!config) {
    throw new Error(`Config not found for tenantAlias: ${tenantAlias}`);
  }

  return config;
};

/**
 * Should be used by backend only because tenantID is not specific enough unless paired with the forDemo flag
 */
export const getConfigFromTenantID = (
  tenantID: number,
  forDemo: boolean,
): TenantAliasConfig => {
  const currentEnvMap = getCurrentEnvMap();

  return Object.values(currentEnvMap).find(
    (config) =>
      config.tenantID === Number(tenantID) && config.isDemo === forDemo,
  ) as TenantAliasConfig;
};

export const isTenantAlias = (value: unknown): value is TenantAlias =>
  typeof value === 'string' && (value as TenantAlias) in tenantAliasConfig;

export const validateTenantAlias = (value: unknown): TenantAlias => {
  if (!isTenantAlias(value)) {
    throw new Error(`Invalid value "${value}" for tenantAlias`);
  }

  return value;
};
